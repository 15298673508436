
    .education-wrapper {
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                padding: 60px;
            }
        }
    }
    .create-content {
        margin: 60px 0;
    }
    .bottom-btn {
        text-align: center;
    }
    .horizontal-form-item {
        display: flex;
        ::v-deep .el-form-item__content {
            margin-left: 20px;
        }
    }

    .first-title {
        font-size: 18px;
        margin-bottom: 22px;
    }
    /*自定义表单*/
    .stepTwo-wrapper {
        .form-item {
            margin-bottom: 22px;
            .item-text {
                font-size: 16px;
                margin-bottom: 15px;
                .iconfont {
                    font-size: 18px;
                    color: #1122D8;
                    margin-right: 10px;
                }
                .subtitle {
                    font-size: 14px;
                    color: #999;
                    margin-left: 20px;
                }
            }
            .item-content {
                .item-line {

                }
                .item-line-half {
                    margin: 0 30px 15px 0;
                    .half-text {
                        width: 56px;
                        text-align: right;
                    }
                    .el-input {
                        width: 1%;
                        flex: 1;
                        margin-left: 15px;
                    }
                }
            }
        }
    }
    .ml12 {
        margin-left: 12px;
        line-height: 32px;
        position: absolute;
        top: 0;
        left: 80px;
    }
    .success-content {
        margin-top: 155px;
        text-align: center;
        .iconfont {
            color: #1FD18C;
            font-size: 52px;
        }
        .text {
            font-size: 24px;
            margin: 20px 0 0;
            font-weight: bold;
        }
    }
